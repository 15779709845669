<template>
  <div class="blogPageWrapper">
    <div class="blogPageContent">
      <div class="blogPageHeader">
        <h4>What's new</h4>
      </div>
      <div class="blogDynamic" />
    </div>
  </div>
</template>

<script>
export default {
  name: "blog",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    let html = "";
    this.$axios({
      method: "get",
      url:
        "https://api.tumblr.com/v2/blog/tjingdg.tumblr.com/posts?api_key=ewqOzKxUQ51LVl3KhOOi9KatPznBASMPcUB18r85E8DvlF31Kb&",
    }).then((response) => {
      const allPosts = response.data.response.posts;
      Object.keys(allPosts).forEach((index) => {
        const post = allPosts[index];
        html += `<div class="blogDynamicContent" id="${post.slug}">`;
        html += `<h6>${post.title}</h6>`;
        html += `<p class="blogDynamicContentDate">${
          post.date.split(" ")[0]
        }</p>`;
        html += `<p>${post.body}</p>`;
        html += `</div><hr>`;
      });
      document.querySelector(".blogDynamic").innerHTML = html;
      setTimeout(() => {
        if (this.$router.currentRoute.hash) {
          const idToScrollTo = this.$router.currentRoute.hash.replace("#", "");

          document.getElementById(idToScrollTo) &&
            document
              .getElementById(idToScrollTo)
              .scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 300);
    });
  },
  beforeCreate() {},
  destroyed() {},
};
</script>
<style>
.blogPageWrapper {
  max-width: 85%;
  margin: 65px auto 0px auto;
}
</style>
